import { SelectInput, SelectInputProps, SelectOption } from './SelectInput';
import { Group, OrganizationOrGroupTypeEnum } from '../../models';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

interface Props extends Omit<SelectInputProps, 'options'> {
  group: Group | null; // Pridajte group ako parameter
}

export function GroupOrOrganizationTypeInput({ group, ...props }: Props) {
  const { t: tCommon } = useTranslation('common');
  const { setFieldValue } = useFormikContext();

  const organizationOrGroupTypeSelectOptions: SelectOption[] = Object.keys(
    OrganizationOrGroupTypeEnum,
  )
    .filter((key) => isNaN(Number(key)))
    .map((key) => ({
      value: OrganizationOrGroupTypeEnum[key as keyof typeof OrganizationOrGroupTypeEnum],
      label: tCommon(`organizationOrGroupType.${key}` as const),
    }));

  useEffect(() => {
    if (group) {
      setFieldValue('organizationOrGroupType', group.organizationOrGroupType);
    }
  }, [group, setFieldValue]);

  return <SelectInput {...props} options={organizationOrGroupTypeSelectOptions} />;
}
