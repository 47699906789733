import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import { ProgressIndicator } from '../../components/ProgressIndicator';
import { Page } from '../../components/Page';
import { useTranslation } from 'react-i18next';
import { FunctionalityProvider } from '../../context/functionality';
import { PageTopBar } from '../../components/PageTopBar';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import React, { useEffect, useState } from 'react';
import { isSubstring } from '../../utils';
import { useEntityActionSnackbar, useRequireFunctionality } from '../../hooks';
import { Device } from '../../models/Device';
import { DevicesList } from './DevicesList';
import { DeviceStateEnum, Organization } from '../../models';
import { AddDeviceToOrganization } from '../../models/AddDeviceToOrganization';

export function ListDevicesPage() {
  const { enqueueEntityDeletedSnackbar, enqueueEntityUpdatedSnackbar } = useEntityActionSnackbar();
  const { t } = useTranslation('device');
  const { hasFunctionality: hasOrganizationFunctionality } =
    useRequireFunctionality('ORGANIZATIONS_READ');

  const [
    { isLoading: isDevicesLoading, isError, data: devices },
    { refetch: refetchDevices },
    { lastFetchDatetime },
  ] = useGetApi<Device[]>(ApiRoutes.Devices);

  const [{ isLoading: isOrganizationsLoading, data: organizations }] = useGetApi<Organization[]>(
    ApiRoutes.Organizations,
    undefined,
    {
      enabled: hasOrganizationFunctionality,
    },
  );

  const [filteredDevices, setFilteredDevices] = useState(devices);

  useEffect(() => {
    setFilteredDevices(devices);
  }, [devices]);

  async function onAddOrganizationHandle(
    deviceIds: string[],
    organizationId: string,
  ): Promise<void> {
    const input: AddDeviceToOrganization = {
      deviceIds: deviceIds,
    };

    await makeApiRequest('POST', `${ApiRoutes.Organization(organizationId)}/add-devices`, input);
    await refetchDevices();
    enqueueEntityUpdatedSnackbar(t('device'));
  }

  async function handleDelete(id: number) {
    await makeApiRequest('DELETE', ApiRoutes.Device(id));
  }

  async function handleDeleteCompleted(device: Device) {
    await refetchDevices();

    enqueueEntityDeletedSnackbar(t('device', { context: device.chassisId }), {
      context: device.chassisId,
    });
  }

  function handleSearch(searchedText: string) {
    if (!devices) {
      setFilteredDevices([]);
    } else {
      if (searchedText === '') {
        setFilteredDevices(devices);
      } else {
        const searchedTextToLower = searchedText.toLowerCase();
        const searchedDevices = devices.filter((device) => {
          const { chassisId, organizationName, note, id, departments, state } = device;
          const matchesDepartment = departments.some((department) =>
            isSubstring(department.name, searchedTextToLower),
          );
          return (
            isSubstring(chassisId, searchedTextToLower) ||
            isSubstring(organizationName, searchedTextToLower) ||
            isSubstring(note, searchedTextToLower) ||
            isSubstring(id, searchedTextToLower) ||
            isSubstring(DeviceStateEnum[state], searchedTextToLower) ||
            matchesDepartment
          );
        });
        setFilteredDevices(searchedDevices);
      }
    }
  }

  if (isDevicesLoading || (isOrganizationsLoading && hasOrganizationFunctionality)) {
    return <ProgressIndicator />;
  }

  return (
    <FunctionalityProvider
      createFunctionality="DEVICES_CREATE"
      readFunctionality="DEVICES_READ"
      updateFunctionality="DEVICES_UPDATE"
      deleteFunctionality="DEVICES_DELETE"
    >
      <>
        <StickyTopAppBar>
          <PageTopBar
            title={t('title.list')}
            addNewRedirectPath={'/devices/new'}
            newRecordDescriptionText={t('title.new')}
            onSearch={handleSearch}
            lastUpdated={lastFetchDatetime}
          />
        </StickyTopAppBar>
        <Page fullWidth={true} noPaper={true} requireFunctionality="DEVICES_READ">
          {!isDevicesLoading && !isError && (
            <DevicesList
              organizations={hasOrganizationFunctionality ? organizations : []}
              devices={filteredDevices}
              onDelete={handleDelete}
              onDeleteCompleted={handleDeleteCompleted}
              onAddOrganizationHandle={onAddOrganizationHandle}
            />
          )}
        </Page>
      </>
    </FunctionalityProvider>
  );
}
