import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import { ProgressIndicator } from '../../components/ProgressIndicator';
import { Group, OrganizationOrGroupTypeEnum } from '../../models';
import { Page } from '../../components/Page';
import { useTranslation } from 'react-i18next';
import { FunctionalityProvider } from '../../context/functionality';
import { PageTopBar } from '../../components/PageTopBar';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import React, { useEffect, useState } from 'react';
import { isSubstring } from '../../utils';
import { useEntityActionSnackbar } from '../../hooks';
import { GroupsList } from './GroupsList';

export function ListGroupsPage() {
  const { enqueueEntityDeletedSnackbar } = useEntityActionSnackbar();
  const { t } = useTranslation('group');
  const [
    { isLoading: isGroupsLoading, isError, data: groups },
    { refetch: refetchDepartments },
    { lastFetchDatetime },
  ] = useGetApi<Group[]>(ApiRoutes.Groups);

  const [filteredGroups, setFilteredGroups] = useState(groups);

  useEffect(() => {
    setFilteredGroups(groups);
  }, [groups]);

  async function handleDelete(id: number) {
    await makeApiRequest('DELETE', ApiRoutes.Group(id));
  }

  async function handleDeleteCompleted(group: Group) {
    await refetchDepartments();

    enqueueEntityDeletedSnackbar(t('group', { context: group.name }), {
      context: group.name,
    });
  }

  function handleSearch(searchedText: string) {
    if (!groups) {
      setFilteredGroups([]);
    } else {
      if (searchedText === '') {
        setFilteredGroups(groups);
      } else {
        const searchedTextToLower = searchedText.toLowerCase();
        const searchedGroups = groups.filter((groups) => {
          const { name, description, organizationOrGroupType } = groups;
          return (
            isSubstring(name, searchedTextToLower) ||
            isSubstring(description, searchedTextToLower) ||
            isSubstring(OrganizationOrGroupTypeEnum[organizationOrGroupType], searchedTextToLower)
          );
        });
        setFilteredGroups(searchedGroups);
      }
    }
  }

  if (isGroupsLoading) {
    return <ProgressIndicator />;
  }

  return (
    <FunctionalityProvider
      createFunctionality="ORGANIZATIONS_CREATE"
      readFunctionality="ORGANIZATIONS_READ"
      updateFunctionality="ORGANIZATIONS_UPDATE"
      deleteFunctionality="ORGANIZATIONS_DELETE"
    >
      <>
        <StickyTopAppBar>
          <PageTopBar
            title={t('title.list')}
            addNewRedirectPath={'/groups/new'}
            newRecordDescriptionText={t('title.new')}
            onSearch={handleSearch}
            lastUpdated={lastFetchDatetime}
          />
        </StickyTopAppBar>
        <Page fullWidth={true} noPaper={true} requireFunctionality="ORGANIZATIONS_READ">
          {!isGroupsLoading && !isError && (
            <GroupsList
              groups={filteredGroups}
              onDelete={handleDelete}
              onDeleteCompleted={handleDeleteCompleted}
            />
          )}
        </Page>
      </>
    </FunctionalityProvider>
  );
}
