import { Card, CardContent } from '@mui/material';
import { useStyles } from './styles';
import React from 'react';
import { NavTab, NavTabPanel, NavTabs } from '../../components/NavTabs';
import { useTranslation } from 'react-i18next';
import { ProtectedRoute } from '../../components/ProtectedRoute';
import { useLocation } from 'react-router';
import { Navigation } from '../../types';
import { getTabsValue } from '../../utils';
import { OrganizationForm, OrganizationFormValues } from './OrganizationForm';
import { SubmitFormCompletedHandler, SubmitFormHandler } from '../../components/Form';
import { FunctionalityProvider } from '../../context/functionality';
import { OrganizationDevicePage } from './organizationDevice/OrganizationDevicePage';
import { Route, Routes } from 'react-router-dom';
import { Group } from '../../models';

interface Props {
  id: string;
  initialValues?: OrganizationFormValues;
  groups?: Group[];
  onSubmit: SubmitFormHandler<OrganizationFormValues>;
  onSubmitCompleted: SubmitFormCompletedHandler;
}

export function OrganizationNavigation({
  id,
  initialValues,
  groups,
  onSubmit,
  onSubmitCompleted,
}: Props) {
  const { t } = useTranslation('organization');
  const location = useLocation();
  const classes = useStyles();

  const organizationPath = `/organizations/${id}`;
  const nav: Navigation = {
    organization: {
      tab: organizationPath,
      route: {
        path: organizationPath,
      },
      subRoutes: {},
    },
    devices: {
      tab: `${organizationPath}/devices`,
      route: {
        path: `${organizationPath}/devices`,
      },
      subRoutes: {},
    },
  };

  return (
    <React.Fragment>
      <NavTabs
        value={getTabsValue(nav, location.pathname)}
        aria-label="organization tabs"
        id="organization-nav-tabs"
      >
        <NavTab
          label={t('organization')}
          to={nav.organization.route.path as string}
          value={nav.organization.tab}
          requireFunctionality="ORGANIZATIONS_READ"
        />
        <NavTab
          to={nav.devices.route.path as string}
          label={t('devices')}
          value={nav.devices.tab}
          requireFunctionality="ORGANIZATIONS_READ"
        />
      </NavTabs>
      <Card className={`${classes.fullWidth} ${classes.boxShadowAndBorderRadius}`}>
        <CardContent>
          <NavTabPanel>
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedRoute requireFunctionality="ORGANIZATIONS_READ">
                    <FunctionalityProvider
                      createFunctionality="ORGANIZATIONS_CREATE"
                      readFunctionality="ORGANIZATIONS_READ"
                      updateFunctionality="ORGANIZATIONS_UPDATE"
                      deleteFunctionality="ORGANIZATIONS_DELETE"
                    >
                      <OrganizationForm
                        initialValues={initialValues}
                        groups={groups || []}
                        onSubmit={onSubmit}
                        onSubmitCompleted={onSubmitCompleted}
                      />
                    </FunctionalityProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/devices"
                element={
                  <ProtectedRoute requireFunctionality="ORGANIZATIONS_READ">
                    <FunctionalityProvider
                      createFunctionality="ORGANIZATIONS_CREATE"
                      readFunctionality="ORGANIZATIONS_READ"
                      updateFunctionality="ORGANIZATIONS_UPDATE"
                      deleteFunctionality="ORGANIZATIONS_DELETE"
                    >
                      <OrganizationDevicePage />
                    </FunctionalityProvider>
                  </ProtectedRoute>
                }
              />
            </Routes>
          </NavTabPanel>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
