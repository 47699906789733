import * as Yup from 'yup';
import { TextInput } from '../../components/TextInput';
import { SubmitFormCompletedHandler, SubmitFormHandler } from '../../components/Form';
import { Box } from '@mui/material';
import { Group, IntegrationTypeEnum, Optional, OrganizationOrGroupTypeEnum } from '../../models';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { FormButton } from '../../components/FormButton';
import { EntityForm } from '../../components/EntityForm';
import { CheckboxInput } from '../../components/CheckboxInput';
import { PasswordInput } from '../../components/PasswordInput';
import { IntegrationTypeSelectInput, SelectOption } from '../../components/SelectInput';
import { useState } from 'react';
import { GroupOrOrganizationTypeInput } from '../../components/SelectInput/GroupOrOrganizationTypeInput';
import { GroupAutoComplete } from '../../components/GroupAutoComplete/GroupAutoComplete';

export interface OrganizationFormValues {
  name: string;
  medicAuthentication: boolean;
  ico: string;
  email: Optional<string>;
  firstName: Optional<string>;
  lastName: Optional<string>;
  password: Optional<string>;
  username: Optional<string>;
  passcodeValidityMinutes: Optional<number>;
  integrationType: Optional<IntegrationTypeEnum>;
  organizationOrGroupType: OrganizationOrGroupTypeEnum;
  groupId: Optional<string>;
}

interface Props<TResponseData = never> {
  initialValues?: OrganizationFormValues;
  groups: Group[];
  onSubmit: SubmitFormHandler<OrganizationFormValues, TResponseData>;
  onSubmitCompleted: SubmitFormCompletedHandler<TResponseData>;
}

export function OrganizationForm<TResponseData = never>({
  initialValues,
  groups,
  onSubmit,
  onSubmitCompleted,
}: Props<TResponseData>) {
  const { t } = useTranslation('organization');
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(() => {
    if (initialValues?.groupId) {
      return groups.find((group) => group.id === initialValues.groupId) || null;
    }
    return null;
  });

  const defaultFormValues: OrganizationFormValues = {
    name: '',
    medicAuthentication: false,
    ico: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    username: '',
    groupId: null,
    passcodeValidityMinutes: 0,
    integrationType: IntegrationTypeEnum.None,
    organizationOrGroupType: OrganizationOrGroupTypeEnum.Paid,
  };

  const options: SelectOption[] = groups.map((x) => ({
    value: x.id,
    label: x.name,
  }));

  const classes = useStyles();

  const updateSchema: Yup.SchemaOf<OrganizationFormValues> = Yup.object({
    name: Yup.string().required(t('name.validation.required')),
    medicAuthentication: Yup.boolean().required(t('medicAuthentication.validation.required')),
    passcodeValidityMinutes: Yup.number().default(0),
    ico: Yup.string().required(t('ico.validation.required')),
    email: Yup.string().nullable().default(null),
    firstName: Yup.string().nullable().default(null),
    lastName: Yup.string().nullable().default(null),
    password: Yup.string().nullable().default(null),
    username: Yup.string().nullable().default(null),
    integrationType: Yup.mixed<IntegrationTypeEnum>()
      .required(t('integrationType.validation.required'))
      .oneOf(
        Object.keys(IntegrationTypeEnum).map(
          (key) => IntegrationTypeEnum[key as keyof typeof IntegrationTypeEnum],
        ) as IntegrationTypeEnum[],
        t('integrationType.validation.oneOf'),
      ),
    groupId: Yup.string().nullable().default(null),
    organizationOrGroupType: Yup.mixed<OrganizationOrGroupTypeEnum>()
      .oneOf(Object.values(OrganizationOrGroupTypeEnum) as OrganizationOrGroupTypeEnum[])
      .required(t('organizationOrGroupType.validation.required'))
      .default(null),
  });

  const createSchema: Yup.SchemaOf<OrganizationFormValues> = Yup.object({
    name: Yup.string().required(t('name.validation.required')),
    medicAuthentication: Yup.boolean().required(t('medicAuthentication.validation.required')),
    passcodeValidityMinutes: Yup.number().default(0),
    ico: Yup.string().required(t('ico.validation.required')),
    email: Yup.string().email(t('email.validation.email')).nullable().notRequired(),
    firstName: Yup.string().required(t('firstName.validation.required')),
    lastName: Yup.string().required(t('lastName.validation.required')),
    password: Yup.string()
      .required(t('password.validation.required'))
      .min(8, ({ min }) => t('password.validation.minLength', { length: min }))
      .max(255, ({ max }) => t('password.validation.maxLength', { length: max }))
      .test('Contains lowercase', t('password.validation.lowercase'), hasLowerCase)
      .test('Contains uppercase', t('password.validation.uppercase'), hasUpperCase),
    username: Yup.string().required(t('username.validation.required')),
    integrationType: Yup.mixed<IntegrationTypeEnum>()
      .required(t('integrationType.validation.required'))
      .oneOf(
        Object.keys(IntegrationTypeEnum).map(
          (key) => IntegrationTypeEnum[key as keyof typeof IntegrationTypeEnum],
        ) as IntegrationTypeEnum[],
        t('integrationType.validation.oneOf'),
      ),
    groupId: Yup.string().nullable().default(null),
    organizationOrGroupType: Yup.mixed<OrganizationOrGroupTypeEnum>()
      .oneOf(Object.values(OrganizationOrGroupTypeEnum) as OrganizationOrGroupTypeEnum[])
      .required(t('organizationOrGroupType.validation.required'))
      .default(null),
  });

  const schema = initialValues === undefined ? createSchema : updateSchema;

  const onGroupSelectChange = (
    event: React.ChangeEvent<unknown>,
    newValue: SelectOption | string | null,
  ) => {
    if (typeof newValue === 'object' && newValue !== null && 'value' in newValue) {
      const group = groups.find((x) => x.id === newValue.value);
      setSelectedGroup(group || null);
    } else {
      setSelectedGroup(null);
    }
  };

  return (
    <EntityForm<OrganizationFormValues, TResponseData>
      initialValues={initialValues || defaultFormValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      onSubmitCompleted={onSubmitCompleted}
    >
      <Box className={classes.formRow}>
        <TextInput id="name" label={t('name.label')} required={true} type="text" />
      </Box>
      <Box className={classes.formRow}>
        <CheckboxInput id="medicAuthentication" label={t('medicAuthentication.label')} />
      </Box>
      <Box className={classes.formRow}>
        <TextInput id="passcodeValidityMinutes" label={t('passcodeValidityMinutes.label')} />
      </Box>
      <Box className={classes.formRow}>
        <TextInput id="ico" label={t('ico.label')} required={true} type="text" />
      </Box>
      <Box>
        <GroupAutoComplete
          id="groupId"
          label={t('groups.label')}
          placeholder={t('groups.label')}
          options={options}
          required={false}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
          freeSolo={true}
          variant="outlined"
          multiline={true}
          onChange={onGroupSelectChange}
        />
      </Box>
      <Box>
        <Box className={classes.formRow}>
          <GroupOrOrganizationTypeInput
            id="organizationOrGroupType"
            label={t('organizationOrGroupType.label')}
            group={selectedGroup}
            withNoneOption={false}
            required={true}
            disabled={selectedGroup == null ? false : true}
          />
        </Box>
      </Box>
      {!initialValues && (
        <>
          <Box className={classes.formRow}>
            <TextInput id="username" label={t('username.label')} required={true} type="text" />
          </Box>
          <Box className={classes.formRow}>
            <TextInput id="firstName" label={t('firstName.label')} required={true} type="text" />
          </Box>
          <Box>
            <TextInput id="lastName" label={t('lastName.label')} required={true} type="text" />
          </Box>
          <Box className={classes.formRow}>
            <TextInput id="email" label={t('email.label')} required={true} type="text" />
          </Box>
          <Box className={classes.formRow}>
            <PasswordInput id="password" label={t('password.label')} required={true} />
          </Box>
        </>
      )}
      <Box className={classes.formRow}>
        <IntegrationTypeSelectInput
          id="integrationType"
          label={t('integrationType.label')}
          required={true}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <FormButton />
      </Box>
    </EntityForm>
  );
}

function hasLowerCase(str?: string): boolean {
  if (!str) {
    return false;
  }

  return str.toUpperCase() !== str;
}

function hasUpperCase(str?: string) {
  if (!str) {
    return false;
  }

  return str.toLowerCase() !== str;
}
