import React, { useState } from 'react';
import { Organization } from '../../models';
import { Box, TextField } from '@mui/material';
import { Autocomplete } from '@mui/lab';
import { Button } from '../Button';

interface Props {
  deviceIds: string[];
  organizations: Organization[];
  onAddOrganizationHandle: (deviceIds: string[], organizationId: string) => Promise<void>;
}

interface Option {
  value: string;
  label: string;
}

export function SearchOrganizationComponent({
  deviceIds,
  organizations,
  onAddOrganizationHandle,
}: Props) {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = organizations.map((x) => ({
    value: x.id,
    label: x.name,
  }));

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: Option | null) => {
    if (event.isTrusted) {
      setSelectedOption(newValue);
    }
  };

  function onButtonClick(): void {
    if (selectedOption) {
      onAddOrganizationHandle(deviceIds, selectedOption?.value);
    }
  }

  return (
    <>
      <Box>
        <Autocomplete
          value={selectedOption}
          onChange={handleChange}
          options={options}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={(params) => <TextField {...params} label="Combo box" />}
          filterOptions={(options, state) => {
            return options.filter((option) =>
              option.label.toLowerCase().includes(state.inputValue.toLowerCase()),
            );
          }}
        />
        <Button text="Pridat" color="primary" onClick={onButtonClick} />
      </Box>
    </>
  );
}
