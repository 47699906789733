import MaterialAutocomplete from '@mui/material/Autocomplete';
import { InputProps, TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { useFormikDisableContext } from '../../context/formikDisable';
import React from 'react';
import { getInputErrorText } from '../InputErrorText';
import { ClassNameMap } from '@mui/styles/withStyles/withStyles';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '../SelectInput';

interface Props {
  id: string;
  label: string;
  required?: boolean;
  getOptionLabel: (option: string | SelectOption) => string;
  options: SelectOption[];
  placeholder?: string;
  hideErrorMessage?: boolean;
  classes?: Partial<ClassNameMap>;
  textFieldInputProps?: Partial<InputProps>;
  noOptionsText?: string;
  freeSolo?: boolean;
  multiline?: boolean;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  onChange?: (event: React.ChangeEvent<unknown>, value: SelectOption | null) => void;
}

export function GroupAutoComplete({
  id,
  label,
  required,
  options,
  getOptionLabel,
  placeholder,
  hideErrorMessage = false,
  classes,
  textFieldInputProps,
  noOptionsText,
  freeSolo = false,
  multiline = false,
  variant = 'standard',
  onChange,
}: Props) {
  const { t } = useTranslation('common');
  const [{ value, onChange: _, ...field }, meta] = useField(id);
  const { isSubmitting, setFieldValue } = useFormikContext();
  const { isDisabled } = useFormikDisableContext();

  const errorMessage = meta.touched ? meta.error : null;

  const selectedOption = options.find((option) => option.value === value) || value;

  return (
    <MaterialAutocomplete
      {...field}
      options={options}
      getOptionLabel={getOptionLabel}
      id={id}
      autoComplete={true}
      value={selectedOption}
      defaultValue={null}
      noOptionsText={noOptionsText ?? t('autoComplete.noOptions')}
      forcePopupIcon={false}
      onChange={(_, newValue) => {
        if (
          newValue &&
          typeof newValue === 'object' &&
          'value' in newValue &&
          'label' in newValue
        ) {
          // Ak je newValue objekt typu Option (SelectOption), ulož newValue.value
          setFieldValue(id, (newValue as SelectOption).value);
        } else {
          // Inak ulož priamo newValue (ak je string alebo null)
          setFieldValue(id, newValue);
        }

        if (onChange) {
          if (Array.isArray(newValue)) {
            // Skontrolujte, či prvá hodnota poľa je SelectOption
            const option =
              newValue[0] &&
              typeof newValue[0] === 'object' &&
              'value' in newValue[0] &&
              'label' in newValue[0]
                ? (newValue[0] as SelectOption)
                : null;
            onChange(_, option);
          } else if (
            newValue &&
            typeof newValue === 'object' &&
            'value' in newValue &&
            'label' in newValue
          ) {
            // Ak je newValue objekt typu SelectOption
            onChange(_, newValue as SelectOption);
          } else {
            // Ak je newValue string alebo null, pošlite null
            onChange(_, null);
          }
        }
      }}
      {...(freeSolo ? { onInputChange: (_, value) => setFieldValue(id, value) } : {})}
      disabled={isSubmitting || isDisabled}
      fullWidth={true}
      freeSolo={freeSolo}
      classes={classes}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin="normal"
          variant={variant}
          required={required}
          error={!hideErrorMessage && !!errorMessage}
          helperText={!hideErrorMessage ? getInputErrorText(errorMessage) : null}
          placeholder={placeholder}
          multiline={multiline}
          InputProps={{ ...params.InputProps, ...textFieldInputProps }}
          InputLabelProps={{
            ...params.InputLabelProps,
            htmlFor: id,
          }}
        />
      )}
    />
  );
}
