import { useNavigate } from 'react-router-dom';
import { ApiRoutes, makeApiRequest } from '../../services/api';
import { GroupCreateOrEditInput, GroupCreateResult } from '../../models';
import { GroupForm, GroupFormValues } from './GroupForm';
import { Page } from '../../components/Page';
import { useTranslation } from 'react-i18next';
import { FunctionalityProvider } from '../../context/functionality';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import { PageTopBar } from '../../components/PageTopBar';
import React from 'react';
import { Box } from '@mui/material';
import { useEntityActionSnackbar } from '../../hooks';

export function CreateGroupPage() {
  const { t } = useTranslation('department');
  const navigate = useNavigate();
  const { enqueueEntityCreatedSnackbar } = useEntityActionSnackbar();

  async function handleSubmit(values: GroupFormValues) {
    const input: GroupCreateOrEditInput = {
      ...values,
    };
    return makeApiRequest<GroupCreateResult, GroupCreateOrEditInput>(
      'POST',
      ApiRoutes.Groups,
      input,
    );
  }

  async function handleSubmitCompleted(data?: GroupCreateResult) {
    if (!data) {
      navigate(-1);

      return;
    }

    navigate(`/groups/${data.id}`, { replace: true });

    enqueueEntityCreatedSnackbar(t('group', { context: data?.id }), { context: data?.id });
  }

  return (
    <FunctionalityProvider
      createFunctionality="ORGANIZATIONS_CREATE"
      readFunctionality="ORGANIZATIONS_READ"
      updateFunctionality="ORGANIZATIONS_UPDATE"
      deleteFunctionality="ORGANIZATIONS_DELETE"
    >
      <>
        <StickyTopAppBar>
          <PageTopBar title={t('title.new')} hideLastUpdated={true} />
        </StickyTopAppBar>
        <Box mt={2}>
          <Page requireFunctionality="ORGANIZATIONS_READ">
            <GroupForm<GroupCreateResult>
              onSubmit={handleSubmit}
              onSubmitCompleted={handleSubmitCompleted}
            />
          </Page>
        </Box>
      </>
    </FunctionalityProvider>
  );
}
