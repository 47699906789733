import * as Yup from 'yup';
import { TextInput } from '../../components/TextInput';
import { SubmitFormCompletedHandler, SubmitFormHandler } from '../../components/Form';
import { Box } from '@mui/material';
import { Optional, OrganizationOrGroupTypeEnum } from '../../models';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { FormButton } from '../../components/FormButton';
import { EntityForm } from '../../components/EntityForm';
import { SelectInput, SelectOption } from '../../components/SelectInput';

export interface GroupFormValues {
  name: string;
  description: Optional<string>;
  organizationOrGroupType: OrganizationOrGroupTypeEnum;
}

interface Props<TResponseData = never> {
  initialValues?: GroupFormValues;
  onSubmit: SubmitFormHandler<GroupFormValues, TResponseData>;
  onSubmitCompleted: SubmitFormCompletedHandler<TResponseData>;
}

export function GroupForm<TResponseData = never>({
  initialValues,
  onSubmit,
  onSubmitCompleted,
}: Props<TResponseData>) {
  const { t } = useTranslation('group');
  const { t: tCommon } = useTranslation('common');
  const defaultFormValues: GroupFormValues = {
    name: '',
    description: '',
    organizationOrGroupType: OrganizationOrGroupTypeEnum.Paid,
  };

  const classes = useStyles();

  const organizationOrGroupTypeSelectOptions: SelectOption[] = Object.keys(
    OrganizationOrGroupTypeEnum,
  )
    .filter((key) => isNaN(Number(key)))
    .map((key) => ({
      value: OrganizationOrGroupTypeEnum[key as keyof typeof OrganizationOrGroupTypeEnum],
      label: tCommon(`organizationOrGroupType.${key}` as const),
    }));

  const schema: Yup.SchemaOf<GroupFormValues> = Yup.object({
    name: Yup.string().required(t('name.validation.required')),
    description: Yup.string().nullable().default(null),
    organizationOrGroupType: Yup.mixed<OrganizationOrGroupTypeEnum>()
      .oneOf(Object.values(OrganizationOrGroupTypeEnum) as OrganizationOrGroupTypeEnum[])
      .required(t('organizationOrGroupType.validation.required'))
      .default(null),
  });

  return (
    <EntityForm<GroupFormValues, TResponseData>
      initialValues={initialValues || defaultFormValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      onSubmitCompleted={onSubmitCompleted}
    >
      <Box className={classes.formRow}>
        <TextInput id="name" label={t('name.label')} required={true} type="text" />
      </Box>
      <Box className={classes.formRow}>
        <SelectInput
          id="organizationOrGroupType"
          label={t('organizationOrGroupType.label')}
          options={organizationOrGroupTypeSelectOptions}
          withNoneOption={false}
          required={true}
        />
      </Box>
      <Box className={classes.formRow}>
        <TextInput
          id="description"
          label={t('description.label')}
          type="text"
          multiline={true}
          rows={5}
          rowsMax={15}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <FormButton />
      </Box>
    </EntityForm>
  );
}
